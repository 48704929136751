
import http from "@/http";
import { Checklist } from "@/interfaces";
import Vue from "vue";
import { Route } from "vue-router";

export interface DataType {
  checklist?: Checklist;
}

export default Vue.extend({
  data(): DataType {
    return {
      checklist: undefined
    };
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/checklists/${to.params.id}`;

    const { data } = await http.get<Checklist>(url);

    next((vm: DataType & Vue) => {
      vm.checklist = data;
    });
  }
});
